.contact_section {
    background-color: #01487A;
    padding-top: 50px;
    height: 90vh;
    /* background-image: url(/src/assets/images/contact/banner.svg); */
    background-image: url('../images/contact/banner.png');
    background-repeat: no-repeat;
    background-position: left;
    /* background-size: cover; */
    background-size: auto;
    /* border-bottom: 1px solid #FFFFFFC4; */
}

footer {
    /* border-top: 1px solid #727272c4; */
}

/* pricing cards css */
.pricing_cards {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.contact_section h1 {
    color: #fff;
    font-family: Raleway, sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
}

.contact_section p {
    color: #fff;
    font-weight: 300;
    /* font-family: Raleway, sans-serif; */
}

.pricing_cards .pricing_container {
    /* background-color: #fff; */
}



/* Pricing tab css */
.wrapper{
    /* max-width: 90vw; */
    /* min-width: 275px; */
    /* margin: 10vh auto; */
  }
  .contact_tabs {
    display: flex;
    /* border: 1px solid #ccc; */
    /* border-bottom: none; */
  }
  
  .contact_tab {
    /* padding: 12px 20px; */
    cursor: pointer;
    background-color: transparent;
    width: 150px; /* adjust as needed */
    text-align: center;
    color: #FFFFFFC4;
    border-bottom: 3px solid #FFFFFFC4;
    padding-bottom: 10px;
  }
  
  .contact_tab.active {
    background-color: transparent;
    color: #1DC0F8;
    border-bottom: 3px solid #1DC0F8;
  }
  
  .contact_tab:hover{
    background: transparent;
  }
  
  .contact_tab.active:first-child{
  }
  .contact_tab.active:last-child{
  }
  
  .active{
    display: block;
  }
  
  .contact_tab-content {
    /* background-color: #fff; */
  }
  
  .contact_tab-content-item {
    display: none;
    max-width: 100%;
    margin-top: -1px;
    padding-inline: 20px;
  }
  
  .contact_tab-content-item.active {
    display: block;
  }

  @media (max-width: 991.98px) {
    .contact_section {
        height: auto;
    }

    .contact_section h1 {
        margin-top: 40px;
    }
  }

  .contact_sales_form {
    background-color: #fff;
    padding: 50px 30px;
    border-radius: 5px;
  }

  .contact_section .contact_sales ul {
    list-style: none;
    color: #fff;
    font-weight: 200;
    padding-left: 0px;
    font-family: 'Open Sans', sans-serif;
  }

  .contact_section .contact_sales ul li {
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
  }

  @media (min-width: 1200px) {
    .contact_section .contact_sales {
        margin-top: 50px !important;
    }
  }

  .fa-square-check:before, .fa-check-square:before {
    color: #1dc0f8;
    margin-right: 5px;
  }