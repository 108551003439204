.integration-hero-section {
    background-color: #01487A;   
    /* background-image: url(/src/assets/images/home-page/banner-background.svg); */
    background-image: url('../images/integration/integration_banner_backgroung.png');
    /* background-size: 100%;
    background-repeat: no-repeat; */
    background-position: 10% 90%;
    background-repeat: no-repeat;
    /* background-size: cover; */
    position: relative;
    background-size: 25%;
}

.integration-hero-section .bg-slider-img {
    padding: 0;
}

.integration-hero-section .box-title {
    color: #fff !important;
    margin-top: 0px;
}

.integration-hero-section .top-pointer {
    color: #1DC0F8 !important;
    font-weight: 500;
}

.integration-hero-section .box-description h3, .integration-hero-section .box-description p {
    color: #fff;
}

.integration-hero-section .box-description p {
    /* font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320))); */
    margin-top: 20px;
}

.integration-hero-section .hollow-white-btn {
    color: #01487A !important;
    background-color: #fff !important;
    margin-top: 25px;
    margin-bottom: 0px;
}

.integration-hero-section .hollow-white-btn-right {
    color: #fff !important;
    background-color: #01487A !important;
}

.full-integration-api .boxed .circle img {
    filter: none; /* IE5+ */
    padding: 20px 20px;
}

.full-integration-api .boxed .circle p {
    text-align: center;
}

.full-integration-api .boxed {
    border: 3px solid #80808033;
    border-radius: 5px;
    box-shadow: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

@media (max-width: 1200px) {
    .integration-hero-section .top-pointer {
        margin-top: 80px;
    }
}

@media (max-width: 991.98px) {
    .full-integration-api .boxed .circle p {
        display: none;
    }

    .full-integration-api .boxed .circle img  {
        padding: 20px 0;
    }
}