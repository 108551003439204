footer .footer-top {
    background-color: #01487A;
    padding: 70px 0 30px;
}

footer h4 {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    /* border-bottom: 2px solid #1DC0F8; */
    line-height: 28px;
    margin: 0 0 15px;
}

footer ul {
    padding-left: 0;
}

footer ul li {
    margin: 0 0 15px;
    list-style: none;
}

footer ul li a {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    color: #fff;
    font-weight: 300;
    cursor: pointer;
    text-decoration: none;
}

footer ul li a:hover {
    font-size: 14px;
    /* line-height: 28px; */
    color: rgb(29, 192, 248);
    /* font-weight: 400; */
    /* cursor: pointer; */
    text-decoration: none;   
}

footer .medarch_mail {
    margin: 20px 0 20px 0px;
}

footer .medarch_mail a {
    font-size: 14px;
    font-weight: 300;
    color: #fff!important;
    line-height: 28px;
    text-decoration: none;
}

footer .medarch_mail a i {
    margin-right: 5px;
}

footer .copyrights-text {
    color: #FFFFFF;
}

.footer-bottom {
    background-color: #105E96;
    padding: 20px 0;
    font-size: 14px;
    line-height: 1.8;
}

.footer-top .follow_us {
    margin: 30px 0 2px 0px;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

.footer-top #social_media {
    display: flex !important;
    justify-content: flex-start;
    gap: 0;

    margin-bottom: 20px;
}

.fab, .fa-brands {
    margin-left: -10px;
    margin-right: 5px;
    padding: 10px;
}

.fab {
    width: 40px !important;
    font-size: 25px;
}

.count-flag {
    display: inline-block;
}

.footer-top .footer-sub-heading  {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: #fff;
    margin: 0 0 15px 10px;
    display: inline-block;
}

.footer-top p {
    font-size: 14px;
    font-weight: 400;
    color: #ccc!important;
    line-height: 28px;
}

.footer-top a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    color: #ccc!important;
    line-height: 28px;
}