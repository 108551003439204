/* pricing banner css */
.pricing-hero-section {
    position: relative;
    height: 50vh;
    /* background-image: url(/src/assets/images/pricing/banner.svg); */
    background-image: url('../images/pricing/banner.svg');
    background-color: #01487A;
    background-repeat: no-repeat;
    background-position: center;
    /* background-size: cover; */
    background-size: 70% 70%;
    text-align: center;
}

.pricing-hero-section .pricing-container {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.pricing-hero-section h1 {
    color: #fff;
    text-align: center;
}

.pricing-hero-section p {
    text-align: center;
    color: #fff;
    margin-top: 20px;
}

/* pricing cards css */
.pricing_cards {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.pricing_cards .pricing_container {
    background-color: #fff;
}



/* Pricing tab css */
.wrapper{
    /* max-width: 90vw; */
    /* min-width: 275px; */
    /* margin: 10vh auto; */
  }
  .pricing_tabs {
    display: flex;
    /* border: 1px solid #ccc; */
    /* border-bottom: none; */
  }
  
  .pricing_tab {
    padding: 12px 20px;
    cursor: pointer;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    background-color: #f6f6f6;
    width: 150px; /* adjust as needed */
    text-align: center;
  }
  
  .pricing_tab.active {
    border-bottom: none;
  border-right: none;
  border-left: none;
    background-color: #fff;
  }
  
  .pricing_tab:hover{
    background: #fff;
  }
  
  .pricing_tab.active:first-child{
    border-left: 1px solid #ccc;
  }
  .pricing_tab.active:last-child{
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  
  .active{
    display: block;
  }
  
  .pricing_tab-content {
    background-color: #fff;
  }
  
  .pricing_tab-content-item {
    border: 1px solid #ccc;
    display: none;
    max-width: 100%;
    margin-top: -1px;
    padding-inline: 20px;
  }
  
  .pricing_tab-content-item.active {
    display: block;
  }
  