// Import all of Bootstrap's CSS
@import "~bootstrap/scss/bootstrap";

// Import all of Bootstrap's CSS


@import "../css/main.css";


// css/normalize
@import "../css/normalize.css";


@import "../css/home.css";
@import "../css/main.css";
@import "../css/normalize.css";
@import "../css/common.css";
@import "../css/navbar.css";
@import "../css/footer.css";
@import "../css/integration.css";
@import "../css/feature.css";
@import "../css/pricing.css";
@import "../css/contact.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');