* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  html {
    /* font-size: 10px; */
  
    /* 10px / 16px = 0.625 = 62.5% */
    /* Percentage of user's browser font-size setting */
    /* font-size: 62.5%; */
    overflow-x: hidden;
  
    /* Does NOT work on Safari */
    /* scroll-behavior: smooth; */
  }
  
  body {
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    font-weight: 400;
    color: #555;
  
    /* Only works if there is nothing absolutely positioned in relation to body */
    overflow-x: hidden;
  }

  /* Intraconnects banner */

  .hero-section {
    /* background-image: url(/src/assets/images/home-page/banner-background.svg); */
    background-image: url('../images/home-page/banner-background.png');
    /* background-size: 100%;
    background-repeat: no-repeat; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .service-container {
    max-width: 1600px !important;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .bg-slider-img {
    /* background: url("/assets/images/company/slider2-bg.jpg") 50% no-repeat; */
    background-size: cover;
    padding: 50px 0;
    height: 100%;
  }
   
  .box-title {
    /* width: 60%; */
    line-height: 70px;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    color: #28B4E3 !important;
    font-size: calc(24px + (48px - 24px) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    margin-top: 80px;
  }

  .box-description p {
    /* font-size: 22px; */
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    font-weight: 300;
    margin-top: 30px;
    color: #000000CC;
    /* line-height: 1.5; */
  }

  .banner-cta-button {
    display: flex;
    gap: 25px;
  }

  .hollow-white-btn {
    background: 0 0;
    color: #105E96;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 20px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 5px;
    /* text-transform: uppercase; */
    line-height: 26px;
    line-height: 26px;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    margin-top: 50px;
    margin-bottom: 30px;
    text-decoration: none;
  }

  .watch-video {
    border: 2px solid #105E96 !important;
    color: #105E96 !important;
  }

  .request-demo {
    background-color: #105E96 !important;
  }

  .hollow-white-btn {
    background: 0 0;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    border: 2px solid #fff;
    /* text-transform: uppercase; */
    line-height: 26px;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    margin-top: 50px;
    margin-bottom: 30px;
    max-width: 220px;
  }

  .testimonials_content_wrap {
    box-shadow: 7px 5px 30px rgb(72 73 121 / 15%);
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 10px;
    text-align: left;
  }

  .tabcontent-item-buid {
    padding: 40px 30px;
    display: flex;
  }

  .build-software-text {
    font-size: 16px;
    line-height: 24px;
    color: #4d5156;
    margin-bottom: 10px;
    padding-top: 10px;
    position: relative;
  }

  .testimonials_name {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    text-align: left;
  }

  .main-slider-btn {
    text-align: inherit;
    background-color: #b0c21f;
    color: #fff;
    line-height: 13px;
    /* font-family: raleway; */
    font-size: 14px;
    margin: 0;
    padding: 10px 20px;
    letter-spacing: 1px;
    font-weight: 700;
    min-height: 0;
    min-width: 0;
    line-height: 1.5;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0;
    border-radius: 3px;
  }

  @media (max-width:400px) {
    .main-slider-btn {
      padding: 7px 12px;
    }
  }

  .main-slider-btn:hover {
    background-color: #2878c9;
    color: #fff;
  }

  .tabcontent-item-video {
    padding: 30px;
    display: flex;
    min-height: 260px;
    width: 100%;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #fff;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
    text-align: left;
    box-shadow: 0 5px 25px -10px rgb(0 0 0 / 25%);
  }

  .slider-client-img {
    width: 124px;
  }

  .animated.slow {
    animation-duration: 2s;
  }

  .hollow-white-btn:hover {
    background: #fff;
    color: #105E96;
  }

  .request-demo:hover {
    color: #fff;
  }

  .customerVideo_box {
    min-height: 250px;
    border-radius: 10px;
  }

  .customerVideo_box img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 260px;
    box-shadow: 0 5px 25px -10px rgba(0, 0, 0, 0.25);
  }

  .customerVideo_box a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    display: flex;
    color: #fff;
    transition: all 0.2s;
  }

  .customerVideo_box .video-play-button .icon-play {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #2879c9;
    z-index: 10;
    top: 50%;
    left: 50%;
    margin-top: -36px;
    margin-left: -36px;
    transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
    z-index: 2;
  }

  .customerVideo_box .video-play-button .icon-play:before {
    display: none;
  }

  .customerVideo_box .video-play-button .icon-play:after {
    display: block;
    border-left: 20px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    z-index: 10;
    top: 50%;
    left: 50%;
    margin-top: -14px;
    margin-left: -6px;
  }

  .customerVideo_box:hover .icon-play {
    transform: scale(1.2);
  }

  .hollow-white-btn-mobile {
    background: 0 0;
    color: #fff;
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    padding: 10px 6px;
    cursor: pointer;
    border: 2px solid #fff;
    /* text-transform: uppercase; */
    line-height: 26px;
    line-height: 26px;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
    margin-top: 20px;
  }

  .card-nav-title {
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
  }

  @media (min-width: 1200px) {
    .hero-section .hide-xl {
      display: none;
    }
  }
  
  @media (max-width: 1280px) {
    .banner-api {
      width: 400px !important;
    }
  }

  @media (max-width: 1200px) {
    .hero-section .hide-lg {
      display: none;
    }

    .banner-api {
      box-shadow: 0px 0px 8px #1DC0F84D;
      border-radius: 10px;
      margin-top: 0 !important;
      width: calc(100% - 5px) !important;
      padding: 15px 20px;
      margin-top: 20px !important;
    }

    .banner-api img {
      width: calc(15% - 10px);
    }
  }

  /* ------------------------Desktops and laptops ------------------------  */

  @media only screen and (min-width: 1200px) {
    .box-title {
      /* width: 60%; */
      line-height: 70px;
      text-align: left;
      color: #fff;
      /* font-size: 48px; */
      font-weight: 700;
    }
  }

  /* ----------- Non-Retina Screens ----------- */

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .box-title {
      /* width: 60%; */
      line-height: 70px;
      text-align: left;
      color: #fff;
      /* font-size: 48px; */
      font-weight: 700;
    }
  }

  /* ----------- Retina Screens ----------- */

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    .box-title {
      /* width: 60%; */
      line-height: 70px;
      text-align: left;
      color: #fff;
      /* font-size: 48px; */
      font-weight: 700;
    }
  }

  @media (max-width: 1920px) and (min-device-pixel-ratio: 2) {}

  @media (max-width: 1920px) {}

  @media (max-width: 1799.98px) {}

  @media (max-width: 1600.98px) {
    .service-container {
      max-width: 1480px !important;
      margin: 0 auto;
      width: 100%;
      flex-direction: column;
      display: flex;
    }
  }

  @media (max-width: 1466.98px) {
    .service-container {
      max-width: 1280px !important;
    }

    .customerVideo_box img {
      height: 280px;
    }
  }

  @media (max-width: 1366.98px) {
    .service-container {
      max-width: 1280px !important;
    }

    .bg-slider-img {
      padding: 40px 0;
    }

    /* .customerVideo_box img {
              height: 280px;
          } */
    /* .tabcontent-item-buid {
              padding: 20px 25px;
              display: flex;
          }
          .tabcontent-item-video {
              padding: 20px;
          } */
  }

  @media (max-width: 1280.98px) {
    .service-container {
      max-width: 1140px !important;
    }
  }

  @media (max-width: 1200.98px) {
    .bg-slider-img {
      padding: 0 0 40px!important;
    }

    .service-container {
      max-width: 960px !important;
    }
  }

  @media (max-width: 1199px) {
    .customerVideo_box img {
      height: 260px;
    }

    .build-software-text {
      font-size: 14px;
      line-height: 18px;
    }

    .box-title {
      line-height: 52px;
      /* font-size: 46px; */
      font-weight: 700;
    }
  }

  @media (max-width: 1152px) {}

  @media (max-width: 1024px) {}

  @media (max-width: 991.98px) {
    .box-title {
      /* width: 60%; */
      line-height: 42px;
      /* font-size: 32px; */
      font-weight: 700;
    }

    .service-container {
      max-width: 720px !important;
    }
  }

  @media (max-width: 851.98px) {}

  @media (max-width: 812px) {}

  @media (max-width: 768.98px) {}

  @media (max-width: 767.98px) {
    .box-description p {
      /* font-size: 18px; */
      /* font-family: 'Proxima Nova', sans-serif; */
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #000000CC;
      margin-left: 5px;
    }

    .box-title {
      line-height: 42px;
      text-align: left;
      color: #fff;
      /* font-size: 24px; */
      font-weight: 700;
    }

    .hollow-white-btn {
      margin: 10px 0 10px;
    }

    .banner-api img {
      width: calc(10% - 1px);
    }

    .banner-api h4 {
      margin-top: 10px !important;
    }

    .banner-api p {
      margin: 10px 0 10px !important;
    }

    .service-container {
      max-width: 540px !important;
    }
  }

  @media (max-width: 575.98px) {
    .getquote-btn {
      background-color: rgb(40, 121, 201);
      color: rgb(255, 255, 255);
      border: 2px solid rgb(40, 121, 201);
      margin-right: 0px;
      margin-left: 0px;
      padding: 4px 7px;
      font-size: 13px;
      margin-top: 0px;
      border-radius: 5px;
    }

    .box-title {
      line-height: 36px;
      text-align: left;
      color: #fff;
      /* font-size: 24px; */
      font-weight: 700;
    }

    .service-container {
      padding-left: 15px;
    }
  }

  @media (max-width: 479.98px) {
    .banner-api img {
      width: calc(13% - 1px);
    }
  }

  @media (max-width: 409.98px) {}

  @media (max-width: 389.98px) {}

  @media (max-width: 360.98px) {}

  @media (max-width: 359.98px) {}

  @media all and (min-width: 241px) and (max-width: 480px) {
    .box-title {
      line-height: 32px;
      text-align: left;
      color: #fff;
      margin-top: 30px;
      /* font-size: 24px; */
      font-weight: 700;
    }
  }

  .banner-api {
    width: 410px;
    margin-top: 120px;
  }

  .banner-api h4 {
    margin-top: 30px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
  font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
  }

  .banner-api p {
    color: #00000099;
    font-family: 'Open Sans', sans-serif;
    margin-top: 20px;
  }


  /* common css for heading and paragraph */
  .common_section {
    padding: 60px 0;
  }

  .common_section .heading_section h2 {
    color: #1DC0F8;
    font-size: calc(22px + (32 - 22) * ((100vw - 320px) / (1920 - 320)));
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
  }

  /* Section-3 CSS */
  .healthcare_innovation_cards .card img {
    filter: invert(50%) brightness(50%) contrast(50%);
    /* filter: rgb(216, 74, 74); */
    /* -webkit-filter: grayscale(1); */
    /* -webkit-transition: all 0s ease-in-out;   */
}

.card .card_img {
  text-align: center;
}

.card .card_img img {
  height: 80px;
  width: 80px;
}

.card:hover img {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
}

  .common_section .common_para {
    margin-top: 34px;
    line-height: 24px;
    color: #00000099;
    font-family: 'Open Sans', sans-serif;
  }

  .boxed {
    box-shadow: 0px 0px 4px #00000029;
    margin-top: 40px;
  }

  .boxed .circle img {
    filter: gray; /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    -webkit-transition: all 0s ease-in-out;  
    /* width: calc(100% - 2px) !important; */
    padding: 30px 0px;
}

@media (max-width: 1200px) {
  .boxed .circle img {
    filter: none; /* IE5+ */
    padding: 20px 0;
}
}

@media (max-width: 575.98px) {
  .boxed .circle img {
    padding: 15px 0px;
  }
}

.circle img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
}

  /* section-4 CSS */

  .healthcare_innovation_cards .row {
    margin-top: 34px;
  }

  .healthcare_innovation_cards .card-body {
    text-align: center;
  }

  .healthcare_innovation_cards img {
    margin: 20px 0 30px;
  }

  .healthcare_innovation_cards h4 {
    margin-bottom: 20px;
    color: #00000099;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }

  .healthcare_innovation_cards .card-body p {
    margin-bottom: 20px;
    color: #00000099;
    font-family: 'Open Sans', sans-serif;
  }

  .healthcare_innovation_cards .card {
    border: none;
    background-color: #F1FCFF;
    border-bottom: 4px solid #F1FCFF;
    /* margin: 20px; */
    padding: 40px 40px;
    height: 100%;
  }

  .healthcare_innovation_cards .card:hover {
    background-color: #01487A;
    border-bottom: 4px solid #1DC0F8;
  }

  .healthcare_innovation_cards .card:hover h4, .healthcare_innovation_cards .card:hover p {
    color: #fff;
  }


  @media (min-width: 767.98px) and (max-width: 981.98px) {
    .healthcare_innovation_cards .card {
      padding: 10px 20px;
    }
  }

  @media (max-width: 767.98px) {
    .healthcare_innovation_cards .card {
      padding: 0px 20px;
    }

    .healthcare_innovation_cards img {
      margin: 15px 0 15px;
    }

    .healthcare_innovation_cards .card-body p {
      margin-bottom: 15px;
    }

    .healthcare_innovation_cards .card {
      border-bottom: 4px solid #1DC0F8;
    }
  }

  /* Developer Detail */
  .developer_detail {
    text-align: center;
    /* background-image: url(/src/assets/images/home-page/developer_detail.png); */
    background-image: url('../images/home-page/developer_detail.png');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
  }

  .developer_detail .developer_type {
    padding-top: 40px;
    text-transform: uppercase;
  }

  .developer_detail p {
    color: #fff;
    margin-top: 12px;
    margin-bottom: 40px;
  }

  .developer_detail h2 {
    color: #fff;
    font-size: calc(1.375rem + 1.5vw);
    /* margin-top: 34px; */
  }


    /* key components css start here */
    .radio_card {
      display: none;
    }
    
    .operations__content,
    .operations2__content {
      display: none;
    }
  
    .operations__content--active,
    .operations2__content--active {
      display: contents;
      grid-template-columns: 7rem 1fr;
      column-gap: 3rem;
      row-gap: 0.5rem;
    }

    .operations__tab-container {
      border-radius: 5px;
      box-shadow: 0 0 20px #00000029;
    }

    .operations__tab-container ul {
      list-style: none;
      margin-top: 1rem;
      padding: 1rem 0 1rem 3.5rem;
      /* border-left: 2px solid #dadfe3 !important; */
    }
  
    .operations__tab-container ul li:hover {
      background-color: #f6f6f6;
    }
  
    /* .components-left {
      box-shadow: 0px 0px 20px #00000029;
      border-radius: 5px;
    } */
  
    .technologies-right {
      padding-left: 55px !important;
      /* background-color: #fafafa; */
      z-index: 0;
    }
  


    /* ---------------new key components css---------------- */
    .stacks-gutter .stacks-data ul {
      border: none;
    }
  
    .stacks-gutter .stacks-data ul li {
      margin-right: 10px;
      border: solid 1px #dadfe3;
      background-color: #fff;
      border-radius: 5px;
      color: #00000099;
      margin-bottom: 20px;
      position: relative;
      padding-left: 25px;
    }

    .stacks-gutter .stacks-data ul li::before {
      content: "";
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: #1DC0F8;
      box-shadow: 0px 0px 6px #1DC0F8;
      left: 0;
      top: 7px;
    }
  
    .operations__tab-container ul li a {
      color: blue !important;
      /* padding-left: 50px; */
    }
  
    .operations__tab-container label {
      cursor: pointer;
    }
  
    .operations-before  {
      cursor: pointer;
      border-left: 2px solid #dadfe3 !important;
    }
  
    .operations__tab label {
      padding: 0 25px;
    }
  
    .operations__tab--active {
      /* background-color: #f6f6f6 !important; */
    }
  
    .operations__tab--active .font-bd {
      color: #1DC0F8;
      /* font-weight: 400; */
    }
  
    .operations__tab:hover .font-bd {
      color: #1DC0F8;
      /* font-weight: 600; */
    }
  
    .font-bd {
      /* font-family: Raleway; */
      color: #00000066;
      margin-top: 22px;
      margin-bottom: 22px;
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  
    ul.tech-last-section {
      margin-left: 0px !important;
    }
  
    .operations-before {
      position: relative;   
    }
  
    .operations__tab--active::before {
    content: "";
    display: block;
    height: 30px;
    left: -2px;
    position: absolute;
    top: 15px;
    width: 2px;
    /* border-radius: 20px; */
    background: #1DC0F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 4px #00000033;
    /* border: 5px solid #FFFFFF; */
    opacity: 1;
    z-index: 1;
    }
  
    .components-heading {
    font-size: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
    line-height: 30px;
    font-family: Raleway,sans-serif;
    font-weight: 800;
    text-align: left;
    margin-top: 1rem;
    color: #00000099;
    }
  
    .commonSection .blue-border-line {
        height: 2px !important;
    }
  
    .stacks-gutter .stacks-data ul li {
      margin-right: 0px;
      border: none;
    }
    
        .technology_content ul {
        margin: 0 -10px
    }
    
    .technology_content ul li {
        padding: 0 10px
    }
    
    .technology_box {
        border-radius: 10px;
        min-height: 80px;
        width: 130px;
        -o-box-shadow: 0 10px 20px rgba(0,0,0,0.05) ;
        -ms-box-shadow: 0 10px 20px rgba(0,0,0,0.05) ;
        -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.05) ;
        -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.05) ;
        box-shadow: 0 10px 20px rgba(0,0,0,0.05) ;
        padding: 15px;
        color: #000;
        font-size: 14px;
        margin-bottom: 20px !important;
        text-align: center;
        margin-right: 10px;
        border: solid 1px #dadfe3;
        background-color: #fff;
        border-radius: 5px;
    }
    
    .technology_box img {
        max-height: 70px;
        margin-bottom: 25px
    }
    
    .progressive__img {
      width: auto;
      height: auto;
    }
  
    #after{
      cursor: pointer;
      display:block;
    }
  
    .subtech-heading {
      margin-top: 25px;
      margin-bottom: 0px !important;
      /* font-weight: 500; */
    }
    
    @media (max-width: 1599px) {
      .technology_box {
            min-height: 180px;
            width: 175px
        }
    
        .technology_box img {
            max-height: 65px;
            margin-bottom: 20px
        }
    }
    
    @media (max-width: 1199.98px) {
      .technology_box {
            min-height: 170px;
            width: 165px
        }
    
        .technology_box img {
            max-height: 60px;
            margin-bottom: 15px
        }
    }
    
    @media (max-width: 991.98px) {
      .technology_box {
            min-height: 160px;
            width: 155px
        }
    
        .technology_box img {
            max-height: 50px;
        }
    }

    @media (max-width: 767.98px) {
      .operations__tab-container {
        box-shadow: none;
        border-radius: none;
      }

      .operations__tab-container ul {
        padding: 0;
      }

      .key-component-accordion .accordion-item .accordion-button {
        border: none !important;
        color: #0009;
      }

      .key-component-accordion .accordion-item {
        color: #0009;
      }

      .key-component-accordion .accordion-item:first-of-type .accordion-button {
        background-color: #fff;
        color: #0009;
        /* padding-bottom: 0px; */
      }

      .key-component-accordion .accordion-button:not(.collapsed) {
        padding-bottom: 0px;
      }

      .key-component-accordion .accordion-button:not(.collapsed) {
        color: #28B4E3;
        font-weight: 500;
      }
      
      .key-component-accordion .accordion-item .accordion-button:hover {
        background-color: #fff;
        color: #28B4E3;
        font-weight: 500;
      }

      .key-component-accordion .accordion {
        --bs-accordion-border-color: none;
        --bs-accordion-inner-border-radius: 0px;
        border-left: 2px solid #d4d3d3;
      }

      .key-component-accordion .accordion-button:not(.collapsed) {
        border-left: 2px solid #28B4E3 !important;
        right: 1.1px;
      }

      .key-component-accordion .accordion-button:after {
        content: none;
      }

      .key-component-accordion .accordion-body {
        border: none;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    @media (max-width: 575.98px) {
      .key-component-accordion .accordion-button:not(.collapsed) {
        right: 1.6px;
      }
    }

    .key-component-accordion .accordion-item .accordion-button:focus {
      border: none;
      box-shadow: none;
    }
    /* Key components css end here */

    /* built with security at heart  */
    .built_security_img {
      display: flex;
      justify-content: center;
      margin: 75px 0;
      gap: 100px;
    }

    .built_security_img img {
      /* width: 110px; */
      width: calc(9% - 1px);
    }

    .alternate-bg {
      background-color: #F1FCFF !important;
    }

    @media (max-width: 991.98px) {
      .built_security_img img {
      width: calc(15% - 1px);
      }

      .built_security_img {
        margin: 50px 0;
      gap: 120px;
      }
    }

    @media (max-width: 575.98px) {
      .built_security_img {
        gap: 100px;
      }
    }

    @media (max-width: 407.98px) {
      .built_security_img img {
      width: calc(20% - 1px) !important;
      }
  
        .built_security_img {
          margin: 50px 0;
          gap: 50px;
        }
    }

    /* carousal CSS */
    .carousel-indicators [data-bs-target] {
      width: 9px !important;
      height: 9px !important;
      border: none !important;
      border-radius: 50px;
    }

    .carousel-indicators [data-bs-target].active {
      color: #000 !important;
    }

    .carousel-indicators {
      justify-content: end !important;
      margin-right: 5% !important;
    }

    .carousel-caption {
      width: 500px;
      /* height: 230px; */
      padding: 40px 45px  !important;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur( 20px );
      -webkit-backdrop-filter: blur( 20px );
      border-radius: 5px;
      text-align: unset !important;
      left: 5% !important;
      bottom: 4.25rem !important;
    }

    .carousel-caption h2 {
      color: #01487A !important;
      line-height: 1.5;
  font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    }

    .carousel-inner button {
      color: #FFFFFF;
      background-color: transparent;
      padding: 6px 15px;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      opacity: 1;
    }

    @media (max-width: 1200px) {
      .carousel-caption {
        bottom: 3.25rem !important;
      }

      .carousel-inner button {
        padding: 4px 10px;
      }
    }

    @media (max-width: 991.98px) {
      .carousel-caption {
        bottom: 2.25rem !important;
        padding: 25px 25px !important;
      }
    }

    @media (max-width: 767.98px) {
      .carousel-item img {
        height: 300px;
        width: auto !important;
      } 

      .carousel-caption {
        width: auto;
      }
    }

    @media (max-width: 451.98px) {
      .carousel-caption {
        padding: 15px !important;
        bottom: 2.25rem !important;
      }
    }

    @media (max-width: 329.98px) {
      .carousel-caption {
        padding: 10px !important;
      }
    }

    
/* Nav Bar Top Sectioin CSS End Here */

/* page title css start here */

#page-title {
  position: relative;
  padding: 1px 0;
  background: #2c71b7 !important;
  background: -moz-linear-gradient(-45deg,
      #2c71b7 0,
      #1cadd9 33%,
      #22c764 65%,
      #b0cb1f 100%);
  background: -webkit-linear-gradient(-45deg,
      #2c71b7 0,
      #1cadd9 33%,
      #22c764 65%,
      #b0cb1f 100%);
  background: linear-gradient(135deg,
      #2c71b7 0,
      #1cadd9 33%,
      #22c764 65%,
      #b0cb1f 100%);
}

/* page title start End css */

/* ============ Header Section CSS start here ================ */

/* -------------- Hamnurger Section css start here --------------- */

header.sticky .hamburger-inner,
header.sticky .hamburger-inner::after,
header.sticky .hamburger-inner::before,
header.sticky .hamburger.is-active .hamburger-inner,
header.sticky .hamburger.is-active .hamburger-inner::after,
header.sticky .hamburger.is-active .hamburger-inner::before {
  background-color: #2879c9 !important;
}

.caret.company-dropdown.show::after,
a.caret.active::after,
li.dropdown.mega-dropdown.caret.has-submenu.show::after {
  content: " " !important;
  width: 11px;
  border-bottom: 2px solid #2879c9 !important;
  top: 20px !important;
}

header.sticky .hamburger.is-active .hamburger-inner {
  background-color: transparent !important;
}

/* Hamburgers */

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #2878c9;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 4px;
  background-color: #2878c9;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
* Spring
*/

.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/* -------------- Hamnurger Section css start here --------------- */

/* 
.main-header {
    background: #fff!important;
    box-shadow: none;
    -khtml-box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    position: absolute;
    width: 100%
} */

.dropdown:hover>.dropdown-menu {
  display: block;
}

nav.navbar .hamburger {
  padding: 0 !important;
}

.hamburger.is-active:hover,
.hamburger:hover {
  opacity: 1;
}

.dropdown>.dropdown-toggle:active {
  pointer-events: none;
}

.menu-open .navbar-brand img,
header.sticky .navbar-brand img {
  filter: none;
}

header {
  position: relative;
  padding: 0;
  z-index: 1000;
  background: #fff !important;
  -webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
}

header a:hover {
  text-decoration: none;
}

.header nav.navbar ul li a.dropdown-item {
  line-height: 45px;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: #2878c9 !important;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 !important;
}

header nav.navbar ul li a {
  font-size: 14px;
  line-height: 70px;
  color: #444;
  font-weight: 700;
  text-transform: uppercase;
  /* letter-spacing: .2px; */
  /* white-space: nowrap; */
  cursor: pointer !important;
  /* display: inline-block!important; */
}

header nav.navbar ul li a.btn {
  line-height: normal;
  padding: 10px 18px;
  color: #fff !important;
  font-size: 15px;
  font-weight: 400;
  background: #b0c21f;
  position: relative;
  border-radius: 0;
}

header nav.navbar ul li a.btn img {
  max-width: 40px;
}

header .btn-theme {
  line-height: normal !important;
  text-transform: capitalize !important;
  font-size: 15px;
  background-color: #fff;
  color: #000 !important;
}

.navbar-toggler-icon {
  /* background-image: url(../img/menu.png); */
  background-size: 30px;
}

/* a.service-drop::before,
header nav.navbar ul li a.nav-item:before {
    position: absolute;
    left: 50%;
    bottom: 10px;
    height: 2px;
    width: 0%;
    content: "";
    -webkit-transform: scale(-1);
    -moz-transform: scale(-1);
    -ms-transform: scale(-1);
    -o-transform: scale(-1);
    transform: scale(-1);
    background: #2878c9;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
} */

header nav.navbar ul li:hover a.nav-item:before {
  width: 100%;
  left: 0;
}

.mega-dropdown a:before {
  display: none;
}

.mega-dropdown a.dropdown-toggle:before {
  display: inline-block;
}

li.mega-dropdown:hover a.dropdown-toggle:before {
  display: inline-block;
  width: 70px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

header .dropdown .dropdown-menu a::before,
header nav.navbar ul li a.btn::before {
  display: none;
}

header nav.navbar ul li a.btn i {
  font-weight: 700;
  margin-right: 5px;
}

header nav.navbar ul li {
  position: relative;
  margin-left: 25px;
}

.menu-show .navbar-nav li a {
  color: #444 !important;
}

.company-dropdown .dropdown-menu {
  font-size: 15px !important;
}

header .dropdown .dropdown-menu {
  min-width: 270px;
  border-radius: 0;
  border: 1px solid #e5e5e5;
  border-top: 0;
  /* margin: 5px auto !important; */
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff;
}

.bdr-bottom {
  border-bottom: 1px solid #e5e5e5;
}

header .dropdown-toggle::after {
  display: none;
}

header .dropdown .dropdown-menu a {
  color: #444;
  text-transform: capitalize;
  font-size: 16px;
  font-family: Raleway, sans-serif;
  padding: 4px 15px 4px;
  line-height: 45px;
  /* font-weight: 500; */
  width: 100%;
  /* display: initial */
  margin-top: 15px;
  margin-bottom: 15px;
}

header .dropdown .dropdown-menu a:last-child {
  /* margin-bottom: 30px; */
}

header .dropdown .dropdown-menu h5:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #edeff3;
  /* background: #444; */
  left: 6px;
  bottom: 0;
  margin: 0 auto;
}

header .dropdown .dropdown-menu a:hover {
  color: #b0c21f;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fff;
}

header .dropdown .dropdown-menu a:first-child {
  border-top: 0;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a.caret::after {
  position: absolute;
  right: 6px;
  top: 0;
  content: "+";
  color: #444;
  font-family: FontAwesome;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  z-index: 2;
  font-weight: 600;
  font-size: 20px;
  display: inline-block !important;
  border: 0;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.hamburger--spring.is-active .hamburger-inner {
  z-index: 1;
}

.sticky {
  position: fixed !important;
  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 0 10px 0 rgba(242, 229, 229, 0.5);
  box-shadow: 0 0 10px 0 rgba(242, 229, 229, 0.5);
  background-color: #fff !important;
  animation: slide-down 0.7s;
}

@keyframes slide-down {
  0% {
    opacity: 0.9;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0.9;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

header.sticky nav.navbar {
  padding: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
}

header.sticky nav.navbar ul li a {
  color: #444;
}

header.sticky nav.navbar ul li a:hover {
  color: #2878c9;
}

/* header.sticky .navbar-toggler-icon {
    background-image: url(../img/bb.png)
} */

header.sticky nav.navbar ul li a.btn-theme {
  background-color: #2878c9;
  color: #fff !important;
}

header.sticky nav.navbar ul li a.btn-theme:hover {
  background-color: #444;
}

.navbar .mega-dropdown {
  position: static !important;
}

.mega-menu {
  /* min-width: 90%!important; */
  margin: auto;
  width: 100%;
  left: 0;
  right: 0;
  overflow-y: auto;
  top: 75px;
  /* height: 80vh */
}

.column {
  width: 1280px;
  margin: 0 auto;
}

.mega-menu {
  min-width: 50% !important;
  margin: auto;
  width: 100%;
  left: 0;
  right: 0;
  overflow-y: auto;
  top: 75 px;
}

.navHead {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(107, 107, 107);
  margin-top: 10px;
}

.card-nav-title a {
  font-size: 1rem !important;
  /* line-height: rem; */
  margin-bottom: 1rem;
  font-weight: 700 !important;
}

.card-nav-title a span {
  text-transform: lowercase !important;
}

.card-nav-title a:hover {
  color: #b0c21f !important;
}

.nav-dropdown-list>li {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

/* .mega-menu li {
    vertical-align: top;
    width: 24%;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    margin-left: 0!important
} */

.mega-menu li {
  vertical-align: top;
  width: 100%;
  /* padding-left: 10px; */
  padding-right: 10px;
  display: inline-block;
  margin-left: 0 !important;
}

.mega-menu li ul li {
  width: 100%;
}

.dropdown-menu a,
.mega-menu a {
  font-size: 15px !important;
  border-top: 0 !important;
  line-height: 40px !important;
  color: #444 !important;
}

.company-dropdown .dropdown-menu a:hover,
.mega-menu a:hover {
  /* color: #b0c21f !important; */
}

.mega-menu li ul li {
  position: relative;
}

/* .mega-menu li ul li::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: #b0cb1f;
    left: 0;
    top: 12px;
} */

.mega-menu li ul li:first-child {
  border-top: 0;
}

.mega-menu li h5 a {
  font-size: 15 !important;
  text-transform: capitalize !important;
  font-weight: 600;
  font-family: Raleway, sans-serif;
  font-weight: 600 !important;
  color: #444444 !important;
}

header.sticky .hamburger-inner,
header.sticky .hamburger-inner::after,
header.sticky .hamburger-inner::before,
header.sticky .hamburger.is-active .hamburger-inner,
header.sticky .hamburger.is-active .hamburger-inner::after,
header.sticky .hamburger.is-active .hamburger-inner::before {
  background-color: #444 !important;
}

.caret.company-dropdown.show::after,
a.caret.active::after,
li.dropdown.mega-dropdown.caret.has-submenu.show::after {
  content: " " !important;
  width: 11px;
  border-bottom: 2px solid #444 !important;
  top: 16px !important;
}

header.sticky .hamburger.is-active .hamburger-inner {
  background-color: transparent !important;
}

.sub-menu {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.sub-menu a {
  line-height: 25px !important;
  padding: 0 0 0 5px !important;
}

.sub-menus {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.getquote-btn {
  background-color: rgb(40, 121, 201);
  color: rgb(255, 255, 255);
  border: 2px solid rgb(40, 121, 201);
  margin-right: 0px;
  margin-left: 0px;
  padding: 8px 12px;
  font-size: 14px;
  margin-top: 0px;
  border-radius: 5px;
}

@media (min-width: 1200px) {

  /* services drop-down */
  .navbar-expand-xl .navbar-nav .services-dropdown-menu {
    background: #ffffff;
    right: 0;
    left: -250px;
    width: 652px;
    border-top: 5px solid #2878c8;
    border-radius: 0 0 30px 30px;
    padding: 21px 14px;
  }

  .navbar-expand-xl .navbar-nav .services-dropdown-menu::before {
    content: "";
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    width: 20px;
    height: 13px;
    background-color: #1568e0;
    position: absolute;
    top: -5px;
    right: 0;
    left: 0;
    margin: 0 auto;
  }

  .services-mega-menu {
    min-width: 50% !important;
    margin: auto;
    width: 100%;
    left: 0;
    right: 0;
    overflow-y: auto;
    top: 75 px;
  }

  /* industries drop-down */
  .navbar-expand-xl .navbar-nav .industries-dropdown-menu {
    background: #ffffff;
    right: 0;
    left: 25px;
    width: 652px;
    border-top: 5px solid #2878c8;
    border-radius: 0 0 30px 30px;
    padding: 21px 14px;
  }

  .navbar-expand-xl .navbar-nav .industries-dropdown-menu::before {
    content: "";
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    width: 20px;
    height: 13px;
    background-color: #1568e0;
    position: absolute;
    top: -5px;
    right: 0;
    left: 0px;
    margin: 0 auto;
  }

  .industries-mega-menu {
    min-width: 70% !important;
    margin: auto;
    width: 100%;
    left: 0;
    right: 0;
    overflow-y: auto;
    top: 75 px;
  }

  /* technologies drop-down */
  .navbar-expand-xl .navbar-nav .technologies-dropdown-menu {
    background: #ffffff;
    right: 0;
    left: 350px;
    width: 652px;
    border-top: 5px solid #2878c8;
    border-radius: 0 0 30px 30px;
    padding: 21px 14px;
  }

  .navbar-expand-xl .navbar-nav .technologies-dropdown-menu::before {
    content: "";
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    width: 20px;
    height: 13px;
    background-color: #1568e0;
    position: absolute;
    top: -5px;
    right: 0;
    left: 0px;
    margin: 0 auto;
  }

  .technologies-mega-menu {
    min-width: 70% !important;
    margin: auto;
    width: 100%;
    left: 0;
    right: 0;
    overflow-y: auto;
    top: 75 px;
  }

  /* ourwork drop-down */
  .navbar-expand-xl .navbar-nav .ourwork-dropdown-menu {
    background: #ffffff;
    right: 0;
    left: 525px;
    width: 652px;
    border-top: 5px solid #2878c8;
    border-radius: 0 0 30px 30px;
    padding: 21px 14px;
  }

  .navbar-expand-xl .navbar-nav .ourwork-dropdown-menu::before {
    content: "";
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    width: 20px;
    height: 13px;
    background-color: #1568e0;
    position: absolute;
    top: -5px;
    right: 0;
    left: 150px;
    margin: 0 auto;
  }

  .ourwork-mega-menu {
    min-width: 50% !important;
    margin: auto;
    width: 100%;
    left: 0;
    right: 0;
    overflow-y: auto;
    top: 75 px;
  }

  /* company drop-down */
  .navbar-expand-xl .navbar-nav .company-dropdown-menu {
    background: #ffffff;
    right: 0;
    left: 625px;
    width: 652px;
    border-top: 5px solid #2878c8;
    border-radius: 0 0 30px 30px;
    padding: 21px 14px;
  }

  .navbar-expand-xl .navbar-nav .company-dropdown-menu::before {
    content: "";
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    width: 20px;
    height: 13px;
    background-color: #1568e0;
    position: absolute;
    top: -5px;
    right: 0;
    left: 300px;
    margin: 0 auto;
  }

  .company-mega-menu {
    min-width: 50% !important;
    margin: auto;
    width: 100%;
    left: 0;
    right: 0;
    overflow-y: auto;
    top: 75 px;
  }

  /* navbar scroll contact button */
  .sticky .contact-button {
    background-color: #b0c21f !important;
    color: #fff !important;
    padding: 10px 15px !important;
    border-radius: 5px;
  }

  .sticky .contact-button:hover {
    background-color: #2878c9 !important;
  }

}

.drop-logo {
  position: absolute;
  /* background: #f2f8ff; */
  padding: 0;
  border-radius: 50%;
  width: 34px;
  text-align: center;
  left: 10px;
  /* top: 40px; */
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-dropdown-list .drop-menu-text {
  text-transform: none;
  color: #666d7a;
  font-family: 'Raleway, sans-serif';
  font-size: 15.5px;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  padding-top: 5px;
  line-height: 22px;
  margin-left: 15px;
}

.drop-menu-text p {
  hyphens: none;
}

.nav-dropdown-list .sub-menu-link {
  margin-left: 15px;
}

.nav-dropdown-list li {
  padding: 0px 10px 0px 50px;
}

.nav-dropdown-list li:hover {
  background-color: #eef2f7;
}

.sub-line-height {
  line-height: 25px !important;
  font-weight: 600 !important;
}

.sub-drop-logo {
  /* top: 18px; */
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
}

.hire-button span {
  border-radius: 5px;
  padding: 12px 22px;
}

.sub-nav-dropdown-list {
  margin-left: 70px;
}

/* ============ Header Section CSS END here ================ */

/* ============  Responsive css start here ================ */

@media (min-width: 768px) {}

@media screen and (min-width: 991px) {}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 1299px) and (max-width: 1366px) {
  .navbar-expand-xl .navbar-nav .services-dropdown-menu {
    left: -375px !important;
  }

  .navbar-expand-xl .navbar-nav .industries-dropdown-menu {
    left: -100px !important;
  }

  .navbar-expand-xl .navbar-nav .technologies-dropdown-menu {
    left: 200px !important;
  }

  .navbar-expand-xl .navbar-nav .ourwork-dropdown-menu {
    left: 375px !important;
  }

  .navbar-expand-xl .navbar-nav .company-dropdown-menu {
    left: 475px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  .navbar-expand-xl .navbar-nav .services-dropdown-menu {
    left: -50px !important;
  }

  .navbar-expand-xl .navbar-nav .industries-dropdown-menu {
    left: 150px !important;
  }

  .navbar-expand-xl .navbar-nav .technologies-dropdown-menu {
    left: 350px !important;
  }

  .navbar-expand-xl .navbar-nav .ourwork-dropdown-menu {
    left: 475px !important;
  }

  .navbar-expand-xl .navbar-nav .company-dropdown-menu {
    left: 550px !important;
  }

  .navbar-expand-xl .navbar-nav .technologies-dropdown-menu::before {
    left: 50px !important;
  }
}

/* @media only screen and (min-width: 1300px) {
  header nav.navbar ul li {
    margin-left: 60px;
  }
} */

/* navbar padding changes */
@media only screen and (min-width: 1200px) and (max-width: 1368px) {
  header nav.navbar ul li {
    margin-left: 0px;
  }

  header nav.navbar ul .nav-item {
    margin-left: 0px;
    padding-left: 12.5px;
    padding-right: 12.5px;
  }

  header nav.navbar ul .first-nav-item {
    padding-left: 25px;
  }

  header nav.navbar ul .last-nav-item {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1300px) {
  header nav.navbar ul li {
    margin-left: 0px;
  }

  header nav.navbar ul .nav-item {
    margin-left: 0px;
    padding-left: 30px;
    padding-right: 30px;
  }

  header nav.navbar ul .first-nav-item {
    padding-left: 60px;
  }

  header nav.navbar ul .last-nav-item {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1400px) {}

@media only screen and (min-width: 1024px) and (max-width: 1300px) {}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {}

@media only screen and (min-width: 991px) and (max-width: 1300px) {}

@media only screen and (min-width: 991px) and (max-width: 1200px) {}

@media (max-width: 1199.98px) {
  .column {
    width: 100%;
  }

  .card-nav-title a {
    margin-bottom: 0;
  }

  .card-nav-title a {
    margin-bottom: 0;
  }

  .sub-menu {
    margin-bottom: 0;
  }

  .drop-logo,
  .sub-drop-logo,
  .navbar-expand-xl .navbar-nav .services-dropdown-menu::before,
  .navbar-expand-xl .navbar-nav .industries-dropdown-menu::before,
  .navbar-expand-xl .navbar-nav .technologies-dropdown-menu::before,
  .navbar-expand-xl .navbar-nav .ourwork-dropdown-menu::before,
  .navbar-expand-xl .navbar-nav .company-dropdown-menu::before,
  .nav-dropdown-list .drop-menu-text {
    display: none;
  }

  .navbar-expand-xl .navbar-nav .industries-dropdown-menu,
  .navbar-expand-xl .navbar-nav .services-dropdown-menu,
  .navbar-expand-xl .navbar-nav .technologies-dropdown-menu,
  .navbar-expand-xl .navbar-nav .ourwork-dropdown-menu,
  .navbar-expand-xl .navbar-nav .company-dropdown-menu {
    border-top: none;
  }

  .nav-dropdown-list li {
    padding: 0;
  }

  .sub-menu {
    margin-top: 0;
  }

  .nav-dropdown-list .sub-menu-link {
    margin-left: 0px;
    font-weight: 700;
  }

  .nav-dropdown-list .sub-points {
    font-weight: 500 !important;
  }

  header .dropdown .dropdown-menu a {
    margin-top: 0px;
  }
}

@media (min-width: 767.98px) {
  .career-list {
    display: none;
  }
}

@media (max-width: 1200px) {
  .contact-list {
    display: none !important;
  }
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 576px) and (max-width: 768px) {}

@media screen and (max-width: 1500px) {}

@media screen and (max-width: 1368px) {}

@media screen and (max-width: 1279px) {}

@media screen and (max-width: 1200px) {
  header nav.navbar ul li a.btn-green {
    line-height: 1.5 !important;
    display: inline-block !important;
  }

  .mega-menu li {
    width: 100%;
  }

  header nav.navbar ul li a::before,
  li.mega-dropdown:hover a.dropdown-toggle:before {
    display: none;
  }

  header nav.navbar ul li a {
    font-size: 15px;
  }

  .sub-nav-dropdown-list {
    margin-left: 0.5rem;
  }

  .sub-line-height {
    font-weight: 500 !important;
  }

  #navbarNav.navbar-collapse {
    background-color: #fff;
    position: fixed !important;
    min-height: 2vh;
    top: 0;
    padding: 0 20px;
    height: 100%;
    left: -100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    display: block;
  }

  #navbarNav.navbar-collapse.menu-show {
    left: 0;
    opacity: 1;
    visibility: visible;
  }

  header .caret {
    position: relative;
  }

  header .caret::after {
    position: absolute;
    content: "+";
    top: 0;
    right: 2px;
    color: #022a5e;
    font-family: FontAwesome;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    z-index: 2;
    font-weight: 600;
    font-size: 20px;
    pointer-events: none;
  }

  /* new css for healthcare button and dropdown */
  header .healthcare-button::after {
    right: 15px !important;
  }

  header nav.navbar ul li {
    margin-left: 0;
  }

  .dropdown:hover>.dropdown-menu {
    display: none;
  }

  .menu-show .navbar-nav li a.dropdown-toggle.active+div.dropdown-menu {
    display: block;
  }

  .company-dropdown.show .dropdown-menu,
  .mega-menu li ul li {
    display: block;
  }

  .dropdown .dropdown-toggle:active {
    pointer-events: auto !important;
  }

  header nav.navbar ul li a {
    display: block !important;
  }

  .navbar .mega-dropdown {
    position: relative !important;
  }

  header .dropdown .dropdown-menu {
    min-width: 100% !important;
    border: 0;
    height: auto;
  }

  .company-dropdown a,
  .sub-menu a {
    line-height: 35px !important;
    font-size: 14px !important;
  }

  header nav.navbar ul li a {
    line-height: 50px;
  }

  .dropdown-menu.border-top {
    border-top: 0 !important;
  }

  .page-ul li {
    width: 100%;
  }

  .company-dropdown .dropdown-item {
    position: relative;
  }

  .company-dropdown .dropdown-item::after {
    position: absolute;
    top: 3px;
    left: 0;
    font-size: 7px;
    color: #b0c21f;
    font-family: FontAwesome;
    content: "\f111";
    font-weight: 600;
  }

  .mega-menu li ul li::before {
    top: 12px;
  }

  header .dropdown .dropdown-menu a:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 991px) {
  .menu-open {
    overflow-y: hidden;
  }
}

@media screen and (max-width: 767px) {}

@media screen and (max-width: 600px) {}

@media screen and (max-width: 576px) {}

@media screen and (max-width: 400px) {}


/* common css for cta button */
.cta-section {
  /* background-image: url(/src/assets/images/common-images/cta-background.svg); */
  background-image: url('../images/common-images/cta-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  padding: 120px 0;
}

@media (max-width: 1400px) {
  .cta-section {
  background-size: auto;
  padding: 60px 0;
  }
}

.cta-section h2 {
  text-align: center;
  color: #01487A !important;
  font-weight: 500;
  margin-bottom: 50px;
}

.cta-section h3 {
  font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
  text-align: center;
  color: #01487A !important;
  font-weight: 400;
  padding: 0 30px;
  line-height: 30px !important;
}

.cta-section .cta-button a {
  text-decoration: none;
  color: #01487A;
  border: 2px solid #01487A;
  border-radius: 4px;
  padding: 7px 15px;
  font-weight: 600;
  font-family: Open Sans, sans-serif;
}

.cta-section .cta-button {
  text-align: center;
  margin-top: 50px;
}

.fab {
  padding: 20px;
  font-size: 30px;
  color: #fff;
  width: 50px;
  text-align: center;
  text-decoration: none;
}


/* Works section css */
.works_Section .common_para {
  color: #fff;
}

.works_Section .works_steps {
  margin-top: 70px;
}

.works_Section .works_steps .row .works_content_right {
  margin-left: 50px;
}

.works_Section .works_steps .row .right_order {
  margin-left: 0;
}

.works_Section .works_steps .row {
  /* margin-bottom: 70px; */
}

.works_Section .works_steps .row .works_content_right h3 {
  color: #FFFFFF;
  margin-bottom: 20px;
  font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
  font-family: 'Open Sans', sans-serif;
}

.works_Section .works_steps .row .works_content_right p {
  color: #FFFFFFCC;
}

@media (max-width: 767.98px) {
  .works_Section .accordion-item:first-of-type .accordion-button {
    margin-top: 20px;
  }

  .works_Section .works_steps {
    margin-top: 30px;
  }
}

/* New works section css */
.works_Section .accordion-item:first-of-type .accordion-button {
  background-color: #105e96;
  color: #fff;
  font-weight: 600;
}

.works_Section .accordion-body {
  background-color: #01487A;
  color: #fff;
}

.works_Section .accordion-button:not(.collapsed) {
  background-color: #01487A !important;
  box-shadow: none;
}

.works_Section .accordion-button:not(.collapsed):after {
  color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.works_Section .accordion-item {
  border: none;
}

.works_Section .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
}

.works_Section .accordion-item:last-of-type .accordion-button.collapsed:focus {
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: unset !important;
  border: none;
  box-shadow: none !important;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.works_Section .works_img {
  border-radius: 5px;
}