.highlights_section .highlight_card {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 30px;
}

.highlights_section .highlight_card .card_image {
    padding: 20px;
    width: 115px;
    box-shadow: 0px 0px 30px #00000033;
    border-radius: 10px;
}

.highlights_section .card_right {
    margin-left: 20px;
}

.highlights_section .highlight_card .card_right p {
    color: #1DC0F8;
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
}

.highlights_section .highlight_card .card_right .highlight_heading span {
    color: #000000CC;
    font-family: Open Sans, sans-serif;
}

.highlights_section .highlight_card .card_right .highlight_content p {
    color: #000000BF;
    font-size: 16px;
    font-weight: 300;
    font-family: Open Sans, sans-serif;
}

.highlights_section .process {
    margin-top: 20px;
}

.highlights_section .process .process_img img {
    padding: 8px;
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 100px;
    text-align: center;
}

.highlights_section .process .process_content p {
    text-align: center;
    margin-top: 15px;
    color: #000000bf;
    font-weight: 300;
}

.highlights_section .process_col {
    text-align: center;
    width: 165px;
}

@media (max-width: 1400px) {
    .highlights_section .process_col {
        width: 142px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .highlights_section .process_col {
        width: 120px;
    }
}


/* promote interoperability of clinical data css */
.clinical_data_card {
    background-color: #F1FCFF;
    text-align: center;
    padding: 80px 20px;
    margin-top: 30px;
}

.clinical_data_card .clinical_data_icon img {
    box-shadow: 10px 10px 25px #00000029;
    width: 90px;
    height: 90px;
    /* border: 6px solid #FFFFFF; */
    /* border-radius: 5px; */
} 

.clinical_data_card .clinical_data_heading p {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #01487A;
    font-weight: 500;
    font-family: Open Sans, sans-serif;
    margin-top: 30px;
}

.clinical_data_card .clinical_data_content p {
    font-family: Open Sans, sans-serif;
    color: #00000099;
}

@media (max-width: 1200px) {
    .highlights_section .highlight_card .card_image {
        width: 100px;
        padding: 15px;
    }
}

@media (max-width: 991.98px) {
    .clinical_data_card {
        padding: 50px 20px;
    }

    .highlights_section .highlight_card {
        padding: 20px;
        background-color: #F1FCFF;
        border-radius: 20px;
    }

    .highlights_section .highlight_card .card_image {
        width: 80px;
        background-color: #fff;
    }
}

.subhighlights .row {
    justify-content: space-between;
}

@media (max-width: 576px) {
    .highlights_section .highlight_card .card_image {
        width: 60px;
        padding: 12px;
    }

    .subhighlights .row {
        justify-content: space-evenly;
    }
}

.clinical_data_odd {
    background-color: #F1FCFF;
}

.clinical_data_odd .clinical_data_card {
    background-color: #fff;
}